import { createInstance } from '@amplitude/analytics-browser';
import { Experiment } from '@amplitude/experiment-js-client';
import { SiteEvents } from '~/repository/amplitude/events/site';

export interface IAmplitudeInstances {
  betting: ReturnType<typeof createInstance>;
  bettingTest: ReturnType<typeof createInstance>;
  default: ReturnType<typeof createInstance>;
}

const getInstance = (apiKey: string) => {
  const instance = createInstance();
  instance.init(apiKey, {
    defaultTracking: {
      pageViews: false,
    },
    flushIntervalMillis: 0,
    transport: 'beacon',
    useBatch: true,
    minIdLength: 1,
  });

  return instance;
};

export default defineNuxtPlugin((nuxtApp) => {
  const {
    public: { amplitudeApiKey, amplitudeBettingApiKey, amplitudeBettingTestApiKey },
  } = useRuntimeConfig();

  nuxtApp.provide('amplitude', {
    betting: getInstance(amplitudeBettingApiKey as string),
    bettingTest: getInstance(amplitudeBettingTestApiKey as string),
    default: getInstance(amplitudeApiKey as string),
  });

  nuxtApp.provide(
    'amplitudeExperiment',
    Experiment.initializeWithAmplitudeAnalytics('client-SzDLCF1CtESlyLmbZIYnDAh9THC15qDQ'),
  );

  nuxtApp.hook('page:finish', () => {
    const route = useRoute();
    const { path, query } = route;

    const referer = document.referrer || 'Direct';

    SiteEvents.view({
      'Page URL': path,
      'Referer': referer,
      'utm_campaign': (query.utm_campaign ?? '') as string,
      'utm_content': (query.utm_content ?? '') as string,
      'utm_medium': (query.utm_medium ?? '') as string,
      'utm_source': (query.utm_source ?? '') as string,
      'utm_term': (query.utm_term ?? '') as string,
    });
  });
});
