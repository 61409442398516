import { storeToRefs } from 'pinia';
import { useBattlesCasesFilterStore } from './casesFilter.store';
import type {
  IBattlesCasesSectionEntity,
  ICreateBattleRequestBody,
  ICreateBattleResponseBody,
} from '~/repository/modules/battles';
import { useCreateBattleOptionsStore } from '~/features/battles/store/createBattleOptions.store';
import { useBattlesSectionsStore } from '~/features/battles/store/sections.store';
import { useSingleBattleStore } from '~/features/battles/store/singleBattle.store';
import { useAlertStore } from '~/store/alert/alert.store';
import { useUserStore } from '~/store/user/user.store';
import type { IBattlesCaseItemInShortBattle, ISelectionCase } from '~/features/battles/types/battlesStore.types';
import { EBattlesStoreNames } from '~/features/battles/constants/general';
import type { IStateChangeSocket } from '~/features/battles/types/webSocketData';
import { BattleEvents } from '~/repository/amplitude/events/battle';
import { ErrorCodes } from '~/api/global/errors/codes/codes';
import { useSiteStatusStore } from '~/features/tech-work/store/useSiteStatusStore';

/* Хранилище выборки кейсов на странице Создания Батла */
export const useCasesSelectionStore = defineStore(EBattlesStoreNames.CASES_SELECTION, () => {
  /* -- Imports -- */
  /* Импорт апи модулей */
  const {
    $api: { battles: BattlesApiService, inventory: InventoryApiService },
    $i18n: { t },
    _route: route,
  } = useNuxtApp();

  const { isBattlesDisabled } = useProjectSettings();

  /* Импорт сторов */
  const alertStore = useAlertStore();
  const userStore = useUserStore();
  const singleBattleStore = useSingleBattleStore();
  const optionsStore = useCreateBattleOptionsStore();
  const casesFilterStore = useBattlesCasesFilterStore();
  const sectionsStore = useBattlesSectionsStore();
  const siteStatusStore = useSiteStatusStore();
  const { isOffline } = storeToRefs(siteStatusStore);

  /* -- Initialisation -- */
  const { state: casesFilterState } = storeToRefs(casesFilterStore);
  const { sections } = storeToRefs(sectionsStore);
  const { optionsState, isBalanceFewerThanBattleCost } = storeToRefs(optionsStore);

  /* -- Const -- */
  /* Массив всех секций, используемых в батлах с дополнительными полями */
  const linkedSections = ref<IBattlesCasesSectionEntity<ISelectionCase>[]>([]);
  /* Коллекция всех кейсов во всех секциях с дополнительными полями */
  const linkedCases = computed<Map<number, ISelectionCase>>(() => {
    const result = new Map();
    linkedSections.value.forEach((section) => {
      section.cases.forEach((singleCase) => {
        result.set(singleCase.id, singleCase);
      });
    });
    return result;
  });
  /* Коллекция выбранных кейсов */
  const selectedCases = ref<Map<number, ISelectionCase>>(new Map());
  /* Состояния загрузки кнопок */
  const apiLoadingState = reactive({
    createBattleLoading: false,
    sellInventoryLoading: false,
  });

  /* -- Getters -- */
  /* Список секция для селектора в фильтре */
  const filterSections = computed(() =>
    linkedSections.value.map((section) => ({ id: section.id, name: section.name })),
  );
  /* Список отфильтрованных секций и кейсов */
  const filteredSectionsByState = computed(() => {
    const isInPriceRange = (caseData: ISelectionCase) => {
      return caseData.price >= casesFilterState.value.priceFrom && caseData.price <= casesFilterState.value.priceTo;
    };

    const isInSearch = (caseData: ISelectionCase) => {
      if (!casesFilterState.value.searchQuery) return true;
      return caseData.dispName.toLowerCase().includes(casesFilterState.value.searchQuery.toLowerCase());
    };

    return linkedSections.value.map((section) => {
      const isShown =
        !casesFilterState.value.selectedSection || section.id === casesFilterState.value.selectedSection.id;

      if (!isShown) {
        return {
          ...section,
          isShown,
        };
      }

      const cases = section.cases.filter((caseData) => {
        const priceValid = isInPriceRange(caseData);
        const searchValid = isInSearch(caseData);
        const chosenValid = !casesFilterState.value.onlyChosenCases || selectedCases.value.has(caseData.id);

        return priceValid && searchValid && chosenValid;
      });

      return {
        ...section,
        cases,
        isShown: cases.length > 0,
      };
    });
  });

  // Определить, является ли модулем БК
  const isBkIntegration = computed(() => route.meta?.isIntegratorModule);

  /* -- Methods -- */
  /**
   * Добавляет кейс к выбранным и меняет его поля selected и amount
   * @param item - Сам кейс
   */
  const addToSelected = (item: ISelectionCase) => {
    if (optionsState.value.roundsAmount >= 50) {
      alertStore.showError({
        message: t('battles.error.tooManyRounds'),
        title: t('battles.error.tooManyRoundsTitle'),
      });
      return;
    }
    if (!linkedCases.value.has(item.id)) return;

    const neededCase = linkedCases.value.get(item.id)!;
    if (!neededCase.selected) {
      neededCase.selected = true;
      neededCase.amount = 1;

      selectedCases.value.set(neededCase.id, neededCase);
    } else {
      neededCase.amount++;
    }

    optionsStore.changeRoundsAmount(1);
    optionsStore.changeResultSelectedCost(neededCase.price);
  };

  /**
   * Убирает кейс из выбранных и меняет его поля selected и amount
   * @param item - Сам кейс
   */
  const removeFromSelected = (item: ISelectionCase) => {
    if (!linkedCases.value.has(item.id)) return;

    const neededCase = linkedCases.value.get(item.id)!;

    neededCase.selected = false;

    optionsStore.changeRoundsAmount(-1 * neededCase.amount);
    optionsStore.changeResultSelectedCost(-1 * neededCase.amount * neededCase.price);
    neededCase.amount = 1;

    selectedCases.value.delete(item.id);
  };

  /* Функция очистки ВСЕХ выбранных кейсов */
  const clearSelectedCases = () => {
    selectedCases.value.forEach((item) => {
      removeFromSelected(item);
    });
  };

  /* Функция выборки кейсов для добавления */
  const prepareSelectedCasesFromOthers = (cases: IBattlesCaseItemInShortBattle[] = []) => {
    clearSelectedCases();

    cases.forEach((otherCase) => {
      if (!linkedCases.value.has(otherCase.id)) return;

      const neededCase = linkedCases.value.get(otherCase.id)!;

      if (otherCase.amount) {
        for (let i = 0; i < otherCase.amount; i++) {
          addToSelected(neededCase);
        }
      } else {
        addToSelected(neededCase);
      }
    });
  };

  /* Функция, выполняющая запрос на создание батла */
  async function createBattle(place: 'top' | 'bottom') {
    try {
      const createBattleBody: ICreateBattleRequestBody = {
        bots: optionsState.value.startWithBots,
        cases: Array.from(selectedCases.value.entries()).map(([id, { amount }]) => ({
          amount,
          id,
        })),
        private: optionsState.value.isBattlePrivate,
        slots: optionsState.value.slotsAmount,
      };

      if (optionsState.value.previousBattleUlid) {
        createBattleBody.previousBattleUlid = optionsState.value.previousBattleUlid;
      }

      apiLoadingState.createBattleLoading = true;

      BattleEvents.battleCreated({
        'Battle Price': optionsState.value.summaryCost,
        'Battle Size': `${optionsState.value.slotsAmount}`,
        'Button Place': `${place} button`,
        'Is Bot': optionsState.value.startWithBots ? 'yes' : 'no',
        'Is Private': optionsState.value.isBattlePrivate ? 'yes' : 'no',
        'Rounds': optionsState.value.roundsAmount,
      });

      const result = await BattlesApiService.createBattle(createBattleBody);

      BattleEvents.participantEntered({
        'Battle Price': optionsState.value.summaryCost,
        'Rounds': optionsState.value.roundsAmount,
      });

      return result;
    } catch (error: unknown) {
      if (error) {
        alertStore.showError({
          message: t('battles.error.alreadyInBattle'),
          title: ErrorCodes.UNPREDICTED_EXCEPTION,
        });
      }
      return error || {};
    } finally {
      apiLoadingState.createBattleLoading = false;
    }
  }

  /* Функция обработки батла после успешного создания */
  async function handleBattleCreate(place: 'top' | 'bottom') {
    if (!isBalanceFewerThanBattleCost.value) {
      const result = (await createBattle(place)) as ICreateBattleResponseBody;
      if (!result) return;

      if (result.balance) {
        userStore.setNewBalance(result.balance);
      }

      if (result.battle) {
        singleBattleStore.battle = result.battle;
        singleBattleStore.isFromCreation = true;
        singleBattleStore.processState.isBattleAtStartPoint = true;

        casesFilterStore.clearState();
        optionsStore.resetOptions();
        clearSelectedCases();

        const localeRoute = useLocaleRoute();
        return navigateTo(localeRoute(ROUTING.BATTLES.BATTLE.getDynamicPath(result.battleUlid)));
      }
    } else {
      await navigateTo(useLocaleRoute()(ROUTING.PAYMENT.MAIN), {
        open: {
          target: '_blank',
        },
      });
    }
  }

  /* Функция для продажи всего инвентаря ( с обновлением профиля ) */
  async function sellAllInventory() {
    apiLoadingState.sellInventoryLoading = true;
    try {
      await InventoryApiService.sellAllItems();
      await userStore.getMe();

      alertStore.show({
        title: t('alerts.allItemsInSaleQueue'),
        type: 'success',
      });
    } catch {
      alertStore.showError({
        title: ErrorCodes.UNPREDICTED_EXCEPTION,
      });
    } finally {
      apiLoadingState.sellInventoryLoading = false;
    }
  }

  /* Обработки сокетного события о продаже предмета */
  function handleSoldItem(data: IStateChangeSocket) {
    if (!data || !data.type || !data.user || !userStore.user) return;
    if (data.type !== 'selled' || userStore.userId !== Number(data.user)) return;

    if (Number(userStore.user.finance.totalAmountItems) - Number(data.price) >= 0) {
      userStore.setNewBalance(Math.abs(Number(data.money)), 'balance');
      userStore.setNewBalance(
        Math.abs(Number(userStore.user.finance.totalAmountItems) - Number(data.price)),
        'totalAmountItems',
      );
      userStore.setNewBalance(Math.abs(Number(data.utastycoin)), 'coinBalance');
    } else {
      userStore.setNewBalance(0, 'totalAmountItems');
    }
  }

  /* Обработка сокетного события об окончании продажи предметов, выставленных ранее на продаже */
  async function handleAllItemsSold(data: { user: string }) {
    if (!data.user || userStore.userId !== Number(data.user)) return;

    await userStore.getMe();

    alertStore.show({
      title: t('alerts.allItemsSold'),
      type: 'success',
    });
  }

  /* -- Side Effects -- */
  /* Отслеживаем обновление секций засчет получения новых */
  watch(
    () => sections.value,
    async (sections) => {
      if (isOffline.value || isBattlesDisabled) return;

      if (!sections.length && !isBkIntegration.value) {
        await sectionsStore.fetchSections();
      }

      linkedSections.value = parseSections(sections, linkedCases.value);
    },
    { deep: true },
  );

  /* -- Returns -- */
  return {
    addToSelected,
    apiLoadingState,
    clearSelectedCases,
    createBattle,
    filterSections,
    filteredSectionsByState,
    handleAllItemsSold,
    handleBattleCreate,
    handleSoldItem,
    linkedSections,
    prepareSelectedCasesFromOthers,
    removeFromSelected,
    selectedCases,
    sellAllInventory,
  };
});

/**
 * Приводит кейсы из секций к плоскому массиву кейсов для удобства
 * @param sections - Секции кейсов
 * @param prevCases - Предыдущие состояние кейсов
 */
function parseSections(
  sections: IBattlesCasesSectionEntity[] = [],
  prevCases: Map<number, ISelectionCase> = new Map(),
) {
  const newSections: IBattlesCasesSectionEntity<ISelectionCase>[] = [];

  sections.forEach((section) => {
    newSections.push({
      ...section,
      cases: section.cases.map((item) => {
        let newCase = {
          ...item,
          amount: 1,
          dispName: item.dispName.trim(),
          selected: false,
        } as ISelectionCase;

        /* Когда мы переходим на страницу, то кейсы ищутся еще раз
         * Следовательно, чтобы сохранить прежнее состояние выбранных кейсов
         * И не перезаписать его на дефолт,
         * Нужно посмотреть, если ли такой кейс в прежних и если да
         * То присвоить вместо дефолтного значения,
         * Чтобы сохранить привязку по ссылке
         */
        if (prevCases.has(item.id)) {
          newCase = prevCases.get(item.id)!;
        }

        return newCase;
      }),
    });
  });

  return newSections;
}
