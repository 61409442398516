export const SOUND_KEYS = {
  CASE: {
    FINAL_DROP: '/case/final_drop.mp3',
    FINAL_POP_UP_OPEN: '/case/final_pop_up_open.mp3',
    FIREBLAST_TARGET: '/case/fireblast_target.mp3',
    FIREBLAST_X2: '/case/fireblast_x2.mp3',
    FIREBLAST_X3: '/case/fireblast_x3.mp3',
    FIREBLAST_X4: '/case/fireblast_x4.mp3',
    MULTICAST_X2: '/case/multicast_x2.mp3',
    MULTICAST_X3: '/case/multicast_x3.mp3',
    MULTICAST_X4: '/case/multicast_x4.mp3',
    MULTICAST1: '/case/multicast1.mp3',
    MULTICAST2: '/case/multicast2.mp3',
    MULTICAST3: '/case/multicast3.mp3',
    REVEAL: '/case/reveal.mp3',
    SCROLL: '/case/scroll.mp3',
    OPEN: '/case/open.mp3',
  },
  DROP: {
    DOWN: '/drop/down.mp3',
    SHOW: '/drop/show.mp3',
  },
  MULTICAST: {
    BOOM: '/multicast/boom.mp3',
    DROP: '/multicast/drop.mp3',
    FAN: '/multicast/fan.mp3',
    FIRE_SHIELD: '/multicast/fire_shield.mp3',
    FIREBLAST_CAST: '/multicast/fireblast_cast.mp3',
    LAUGH: '/multicast/laugh.mp3',
    OOPS: '/multicast/oops.mp3',
    TREASURE_COUNT: '/multicast/treasure_count.mp3',
    X2_WIN: '/multicast/x2_win.mp3',
    X3_LOSE: '/multicast/x3_lose.mp3',
    X3_WIN: '/multicast/x3_win.mp3',
    X4_LOSE: '/multicast/x4_lose.mp3',
  },
  TECHIES: {
    CLICK: '/techies/click.mp3',
    LOSE: '/techies/lose.mp3',
    WIN: '/techies/win.mp3',
  },
  UPGRADES: {
    LOSE: '/upgrades/lose.mp3',
    WIN: '/upgrades/win.mp3',
  },
};
