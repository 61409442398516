import type { CSSProperties } from 'vue';
import type { Property, PropertyValue } from 'csstype';
import { useMedia } from '../composables/useMedia';
import { Colors } from '../constants/colors.constants';
import type { LinearGradientConfiguration, ValueOf } from '~/types/deprecated.types';
import type {
  TBackgroundColor,
  TBorderRadius,
  TBorderRadiusCornersConfiguration,
  TBreakpoints,
  TMargin,
  TMarginSidesConfiguration,
  TPadding,
  TPaddingSidesConfiguration,
} from '~/types/Shared.types';

type TCssUnits = 'px' | '%' | 'deg';
type TTargetConvertUtil = number | string | Array<unknown> | Record<string, unknown>;

/**
 * Возвращает функцию для преобразования значения к определенной системе исчисления
 * @param {TCssUnits} unit - Денежное значение
 * @returns {ReturnType<createConvertUtil>>} - Отформатированное денежное значение с валютным знаком
 */
const createConvertUtil = (unit: TCssUnits) => {
  function convert(target: number): string;
  function convert(target: string): string;
  function convert(target: number | string): string;
  function convert(target: Array<unknown>): Array<string>;
  function convert(target: Record<string, unknown>): Record<string, string>;
  function convert(target: TTargetConvertUtil) {
    if (target === null || target === undefined) return 0;

    if (Array.isArray(target)) {
      return target.map((item) => (item as string).toString() + unit);
    } else if (typeof target === 'string' || typeof target === 'number') {
      return isNaN(Number(target)) ? target.toString() : target.toString() + unit;
    } else {
      return Object.keys(target).reduce((accumulator: Record<string, string>, key) => {
        accumulator[key] = target[key]?.toString() + unit;
        return accumulator;
      }, {});
    }
  }

  return convert;
};

/**
 * Возвращает linear-gradient свойство, создаваемое из цвета или массива цветов
 * @param {string | Array<string>} colors - Цвет или массив цветов
 * @param {string} direction - Направление градиента
 * @param {boolean} webkit - Использовать ли префикс -webkit-
 * @returns {string} - linear-gradient свойство
 */
const fillGradient = (colors: string | string[], direction?: string, webkit = false) => {
  const property = `${webkit ? '-webkit-' : ''}linear-gradient`;
  return `${property}(${direction ? direction + ',' : ''}${Array.isArray(colors) ? colors.join(',') : colors})`;
};

/**
 * Возвращает объект с двумя CSS свойствами из переданной конфигурации
 * создания линейного градиента.
 * Удобно при использовании с Object.assign или деструктуризацией
 * @param {LinearGradientConfiguration | string} gradient - Цвет или массив цветов
 * @param {string} direction - Направление градиента
 * @param {boolean} webkit - Использовать ли префикс -webkit-
 * @returns {{
 *   backgroundImage: string,
 *   backgroundColor?: string,
 * }} - Объект с градиентом и фолбеком
 */
const createLinearGradient = (gradient?: LinearGradientConfiguration | string, direction = '', webkit = false) => {
  const style: CSSProperties = {};

  /* Refactor */
  const checkIsGradientValid = (gradient?: LinearGradientConfiguration | string) => {
    if (!gradient) return false;
    if (typeof gradient === 'string') return !gradient.includes('none') && gradient.includes(',');
    return !!gradient;
  };

  if (typeof gradient === 'string') {
    if (checkIsGradientValid(gradient)) {
      style.backgroundImage = fillGradient(gradient, direction, webkit);
    } else {
      style.backgroundImage = gradient;
    }
    return style;
  }

  if (!gradient) return style;

  if (gradient.fallback) {
    style.backgroundColor = gradient.fallback;
  }

  if (gradient.colorStops || gradient.toDirection) {
    style.backgroundImage = fillGradient(gradient.colorStops, gradient.toDirection, webkit);
  }

  return style;
};

/**
 * Функция преобразования к пиксельной системе исчисления
 */
const toPx = createConvertUtil('px');
/**
 * Функция преобразования к процентной системе исчисления
 */
const toPercents = createConvertUtil('%');
/**
 * Функция преобразования к градусной системе исчисления
 */
const toDegs = createConvertUtil('deg');

/**
 * Проверяет переданное значение и возвращает валидное значение CSS свойства, с авто преобразованием чисел
 * @param {string | number | ValueOf<PropertyValue<unknown>>} value - Значение CSS свойства
 * @param {TCssUnits} unit - Единица измерения для авто преобразования
 * @returns {ValueOf<PropertyValue<unknown>>} - Валидное значение CSS свойства
 */
const proceedCssValue = (
  value: string | number | ValueOf<PropertyValue<unknown>>,
  unit: TCssUnits = 'px',
): ValueOf<PropertyValue<unknown>> => {
  if (!value) {
    // @ts-expect-error TODO remove or refactor
    return;
  }
  // @ts-expect-error TODO remove or refactor
  if (typeof value === 'number' || /^[0-9]*$/.test(value)) return createConvertUtil(unit)(value);
  // @ts-expect-error TODO remove or refactor
  return value;
};

/**
 * Возвращает белый или черный цвет текста в зависимости от яркости фона
 * @param {Array<string>} hexColors - Любое количество аргументов с цветами используемых для создания фона
 * @returns {Colors.NEUTRAL.BLACK | Colors.NEUTRAL.WHITE} - Белый или черный цвет
 */
const pickTextColor = (...hexColors: string[]): string => {
  const brightness =
    hexColors.reduce((acc, color) => {
      const { r, g, b } = GlobalUtils.Colors.hex2Rgb(color, 'object');
      acc += Math.round(
        (parseInt(r.toString()) * 299 + parseInt(g.toString()) * 587 + parseInt(b.toString()) * 114) / 1000,
      );
      return acc;
    }, 0) / hexColors.length;

  return brightness > 125 ? Colors.NEUTRAL.BLACK : Colors.NEUTRAL.WHITE;
};

/**
 * Возвращает объект с двумя CSS свойствами из переданной конфигурации
 * создания линейного градиента и обрабатывает максимальную вариативность переданных типов данных.
 * Удобно при использовании с Object.assign или деструктуризацией
 * @param {LinearGradientConfiguration | Property.BackgroundColor | Property.BackgroundColor[]} color - Цвет, или массив цветов, или
 * объект конфигурации линейного градиента
 * @param {{
 *    direction?: string;
 *    webkit?: boolean;
 * }} options - Опции при парсинге переданной конфигурации цвета(ов)
 * @returns {{
 *   backgroundImage: string,
 *   backgroundColor?: string,
 * }} - Объект с градиентом и фолбеком
 */
const parseColor = (
  color: LinearGradientConfiguration | Property.BackgroundColor | Property.BackgroundColor[],
  options: {
    // Направление градиента, если передан массив цветов
    direction?: string;
    // Использовать ли -webkit- префикс для возвращаемых значений
    webkit?: boolean;
  } = {
    direction: '0deg',
    webkit: false,
  },
): {
  // Если передан обычный цвет или градиент собрался с ошибкой
  backgroundColor?: Property.BackgroundColor;
  // Возвращается всегда со значением градиента или "none"
  backgroundImage: Property.BackgroundImage;
} => {
  const styles: CSSProperties = {};

  const checkIsGradientValid = (gradient?: LinearGradientConfiguration | string) => {
    if (!gradient) return false;
    if (typeof gradient === 'string') {
      return !gradient.includes('none') && gradient.includes(',') && !gradient.includes('linear-gradient');
    }
    return !!gradient;
  };

  if (Array.isArray(color)) {
    if (color.length === 1) styles.backgroundColor = color[0];
    else styles.backgroundImage = fillGradient(color, options.direction, options.webkit);
  } else if (typeof color !== 'string') {
    Object.assign(
      styles,
      createLinearGradient(color as LinearGradientConfiguration, options.direction, options.webkit),
    );
  } else if (color) {
    if (color.includes('linear-gradient')) {
      styles.backgroundImage = color;
    } else if (checkIsGradientValid(color)) {
      styles.backgroundImage = fillGradient(color, options.direction, options.webkit);
    } else {
      styles.backgroundImage = 'none';
      styles.backgroundColor = color;
    }
  }

  return styles as {
    backgroundColor?: Property.BackgroundColor;
    backgroundImage: Property.BackgroundImage;
  };
};

/**
 * Возвращает строку с обработанными цветами/цветом с двумя CSS
 * Удобно при использовании с свойством background в SCSS
 * @param {TBackgroundColor} color - Цвет, или массив цветов, или
 * объект конфигурации линейного градиента
 * @returns {string} строку с обработанным цветом
 **/
const getBackgroundColor = (color: TBackgroundColor): string => {
  if (Array.isArray(color)) return color.length > 1 ? fillGradient(color) : color[0];
  if (typeof color === 'object')
    return `${fillGradient(color.colorStops, color.toDirection)}${color.fallback ? ', ' + color.fallback : ''}`;
  return color;
};

type TSpacing = TMarginSidesConfiguration | TPaddingSidesConfiguration;
function handleBlockSpacing(
  property: 'margin',
  spacing: TMargin,
): {
  margin?: Property.Margin;
  marginBottom?: Property.MarginBottom;
  marginLeft?: Property.MarginLeft;
  marginRight?: Property.MarginRight;
  marginTop?: Property.MarginTop;
};
function handleBlockSpacing(
  property: 'padding',
  spacing: TPadding,
): {
  padding?: Property.Padding;
  paddingBottom?: Property.PaddingBottom;
  paddingLeft?: Property.PaddingLeft;
  paddingRight?: Property.PaddingRight;
  paddingTop?: Property.PaddingTop;
};
/**
 * Возвращает объект CSS со свойствами margin или padding, в зависимости от того, какое свойство нужно получить на выходе.
 * Удобно при использовании с Object.assign или деструктуризацией
 * @param {"margin" | "padding"} property - Свойство, которое требуется получить на выходе
 * @param {TMargin | TPadding} spacing - Значения для создания padding или margin
 * @returns {CSSProperties} - Значения padding или margin в виде объекта CSS свойств
 */
function handleBlockSpacing(property: 'margin' | 'padding', spacing: TMargin | TPadding): CSSProperties {
  const { capitalize } = GlobalUtils.Text;
  const result: CSSProperties = {};
  const checkValue = (value: typeof spacing | undefined) => value === 0 || !!value;

  if (!checkValue(spacing)) return result;
  if (typeof spacing === 'string' || typeof spacing === 'number') {
    result[property] = proceedCssValue(spacing);
  } else {
    const objectSpacing = spacing as TSpacing;
    Object.keys(objectSpacing).forEach((key) => {
      const typedKey = key as keyof TSpacing;
      if (checkValue(objectSpacing[typedKey])) {
        result[`${property}${capitalize(typedKey)}`] = proceedCssValue(objectSpacing[typedKey]!);
      }
    });
  }

  return result;
}

type TChangeCb = <T = unknown>(matchValue: T, mismatchValue: T) => T;
/**
 * Возвращает функцию для изменения значения в зависимости от текущего брейкпоинта
 * @param {TBreakpoints} breakpoint - Брейкпоинт для изменения
 * @param {"up" | "down"} direction - Направление адаптивности (mobile-first или desktop-first)
 * @returns {TChangeCb} - Callback для изменения значения в зависимости от брейкпоинта
 */
const changeByMedia = (breakpoint: TBreakpoints, direction: 'up' | 'down' = 'up'): TChangeCb => {
  return <T = unknown>(matchValue: T, mismatchValue: T): T => {
    const media = useMedia(direction);
    return media[breakpoint.toLowerCase() as Lowercase<TBreakpoints>].value ? matchValue : mismatchValue;
  };
};

const handleBorderRadius = (radiusValue: TBorderRadius) => {
  const { capitalize } = GlobalUtils.Text;
  const result: CSSProperties = {};
  const checkValue = (value: typeof radiusValue | undefined) => value === 0 || !!value;

  if (!checkValue(radiusValue)) return result;
  if (typeof radiusValue === 'string' || typeof radiusValue === 'number') {
    result.borderRadius = proceedCssValue(radiusValue);
  } else {
    const radiusObject = radiusValue as TBorderRadiusCornersConfiguration;
    Object.keys(radiusObject).forEach((key) => {
      const typedKey = key as keyof TBorderRadiusCornersConfiguration;
      if (checkValue(radiusObject[typedKey])) {
        result[`border${capitalize(typedKey)}Radius`] = proceedCssValue(radiusObject[typedKey]!);
      }
    });
  }

  return result;
};

export default {
  CSS: {
    createLinearGradient,
    fillGradient,
    getBackgroundColor,
    handleBlockSpacing,
    handleBorderRadius,
    parseColor,
    pickTextColor,
    proceedCssValue,
  },
  Converting: {
    createConvertUtil,
    toDegs,
    toPercents,
    toPx,
  },
  Media: {
    changeByMedia,
  },
};
