import { defineStore } from 'pinia';
import { AlertCodes } from './alert.messages';
import type { IAlert, IAlertIdentify } from '~/store/alert/alert.types';
import { ErrorCodes } from '~/api/global/errors/codes/codes';

export const useAlertStore = defineStore('global/alert', () => {
  const { snake2Camel } = GlobalUtils.Text;
  const { t } = useNuxtApp().$i18n;
  const DEFAULT_DELAY_ALERT = 3000;
  const alertList = ref<Record<string, IAlertIdentify>>({});

  const delayAnimation = computed(() => {
    return DEFAULT_DELAY_ALERT / 1000;
  });

  // Закрыть окно по idx
  const close = (id: string) => {
    if (alertList.value[id]) {
      delete alertList.value[id];
    }
  };

  // Добавить id каждому элементу
  const createAlert = (options: IAlert): IAlertIdentify => {
    return {
      ...options,
      id: GlobalUtils.Maths.createUniqueId(),
    };
  };

  // Скрыть алёрт по истечению времени
  const closeAfterDelay = (id: string) => {
    setTimeout(() => close(id), DEFAULT_DELAY_ALERT);
  };

  // Подготовить перевод для заголовка или сообщения алерта по ключу или вернуть переданную строку

  const getTranslation = (line: string) => {
    const isValidCode = (codes: Record<string, string>) => Object.values(codes).includes(line);

    if (isValidCode(AlertCodes)) return t(`alerts.${snake2Camel(line)}`);
    if (isValidCode(ErrorCodes)) return t(`errors.${snake2Camel(line)}`);
    return line;
  };

  // Показать окно алёрта и скрыть через определённое/дефолтное время
  const show = (options: IAlert, isDelay?: boolean) => {
    options.message = options.message ? getTranslation(options.message) : options.message;
    options.title = options.title ? getTranslation(options.title) : options.title;
    const fullOptions = createAlert(options);
    const id = fullOptions.id;
    fullOptions.isDelay = isDelay || true;

    // Если оповещений вылезает больше 10 одновременно, ставить 11-е в низ списка
    if (Object.keys(alertList.value).length > 10) {
      alertList.value[Object.keys(alertList.value)[0]] = fullOptions;
    } else {
      alertList.value[id] = fullOptions;
    }

    if (fullOptions.isDelay) closeAfterDelay(id);
  };

  // Показать окно алёрта с ошибкой
  const showError = (options: Omit<IAlert, 'type'>, isDelay?: boolean) => {
    return show(
      {
        ...options,
        type: 'error',
      },
      isDelay,
    );
  };

  return {
    alertList,
    close,
    delayAnimation,
    show,
    showError,
  };
});
