<template>
  <div class="error-wrapper">
    <UiImage class="error-image" :src="errorImage" />

    <div class="error-content">
      <h2 v-if="errorData?.title" class="error-title">
        {{ errorData?.title }}
      </h2>
      <h2 v-else class="error-title">
        {{ t('oops') }}!<br v-if="!isTablet" />
        {{ t('errors.unpredicted') }}.
      </h2>
      <p v-if="errorData?.description" class="error-description">
        {{ errorData.description }}
      </p>
      <p v-else class="error-description">
        {{ t('weApolologize') }}<br />
        {{ t('pleaseReturnMain') }}
      </p>
      <SharedButtonNew :text-props="textProps" class="error-button" @click="errorHandler">
        {{ t('returnToMain') }}
      </SharedButtonNew>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Breakpoints } from '~/constants/media.constants';
import { FontSizes } from '~/types/SharedFont.types';
import { useGlobalErrors } from '~/store/error/error';

const globalErrorsStore = useGlobalErrors();

const { errorData } = storeToRefs(globalErrorsStore);

const nuxtApp = useNuxtApp();
const { t } = useI18n();

const errorImage = computed<string>(() => {
  if (isBkIntegration.value) return '/static/error-iframe.png';

  return '/static/error/error.png';
});

const errorHandler = () => {
  clearError();
  if (isBkIntegration.value) return reloadNuxtApp();
  globalErrorsStore.setErrorData(null);
  GlobalUtils.Routing.goExternalLink(useLinks().MAIN);
};

const viewport = useViewport();

const isTablet = computed(() => {
  return viewport.isGreaterOrEquals(Breakpoints.md);
});

const textProps = computed(() => {
  return { size: viewport.isGreaterOrEquals(Breakpoints.xl) ? FontSizes.LARGE : FontSizes.MEDIUM };
});

// Определить, является ли модулем БК
const isBkIntegration = computed(() => {
  return nuxtApp._route.meta?.isIntegratorModule;
});
</script>

<style scoped lang="scss">
@use 'assets/styles/media';

.error-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  min-height: 100vh;
  padding: var(--gap-4xl) var(--gap-4xs);
  overflow: hidden;
  background-color: var(--main-color-900);

  .error-content {
    .error-button {
      width: 200px;
      height: 40px;
      font-style: normal;
      font-weight: var(--bold);
      color: var(--neutral-white);
      text-align: center;
      background-color: var(--main-color-200);
      border-radius: var(--border-radius-3xl);

      &:hover {
        background-color: var(--main-color-100);
      }

      @include media.media-breakpoint-up(xl) {
        width: 240px;
        height: 50px;
      }
    }
  }
}

.error-image {
  width: 526px;
  height: auto;

  @include media.media-breakpoint-up(md) {
    width: 684px;
  }

  @include media.media-breakpoint-up(xl) {
    width: 1004px;
  }
}

.error-content {
  position: relative;
  top: -16px;
  display: flex;
  flex-direction: column;
  gap: var(--gap-s);
  align-items: center;
  justify-content: flex-start;

  @include media.media-breakpoint-up(xl) {
    top: -24px;
    gap: var(--gap-l);
  }
}

.error-title {
  font-family: var(--secondary-font);
  font-size: var(--font-size-xl);
  font-style: normal;
  font-weight: var(--bold);
  line-height: normal;
  color: var(--neutral-white);
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 2px;

  @include media.media-breakpoint-up(xl) {
    font-size: var(--font-size-2xl);
    letter-spacing: 2.4px;
  }
}

.error-description {
  font-family: var(--roboto);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: var(--medium);
  line-height: normal;
  color: var(--neutral-white);
  text-align: center;

  @include media.media-breakpoint-up(xl) {
    font-size: var(--font-size-l);
  }
}
</style>
