import { EPromocodePopupEvents, type IPromocodePopupUnifiedProps } from '../types/promocode-popup.types';

export class PromocodePopupEvents {
  static claimBonusClicked(event: IPromocodePopupUnifiedProps) {
    return useAmplitude().sendEvent('DEFAULT', {
      event: EPromocodePopupEvents.CLAIM_BONUS_CLICKED,
      eventProperties: event,
    });
  }

  static popupClosed(event: IPromocodePopupUnifiedProps) {
    return useAmplitude().sendEvent('DEFAULT', {
      event: EPromocodePopupEvents.POPUP_CLOSED,
      eventProperties: event,
    });
  }

  static popupOpened(event: IPromocodePopupUnifiedProps) {
    return useAmplitude().sendEvent('DEFAULT', {
      event: EPromocodePopupEvents.POPUP_OPENED,
      eventProperties: event,
    });
  }
}
