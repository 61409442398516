const DEFAULT_VOLUME = 0.5;

export const useVolume = () => {
  const volume = useCookie('volume', {
    default: () => DEFAULT_VOLUME,
  });

  const mute = () => (volume.value = 0);
  const unmute = () => (volume.value = DEFAULT_VOLUME);

  return { volume, mute, unmute };
};
