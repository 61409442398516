import SeoRepository from '~/features/seo/repository/api';
import type { ISeoApiData } from '~/features/seo/types/api.types';

export const useSeoWatcher = async () => {
  const route = useRoute();
  const {
    seo: { title: defaultTitle, description: defaultDescription },
  } = useProjectSettings();

  const defaultSeoData: Pick<ISeoApiData, 'title' | 'description'> = {
    title: defaultTitle,
    description: defaultDescription,
  };

  const { data } = await useAsyncData(
    'seo-data',
    async () => {
      return SeoRepository.getSeo(route.path).catch(() => defaultSeoData);
    },
    {
      watch: [() => route.fullPath],
    },
  );

  watch(
    data,
    (seoData) => {
      if (!seoData) return;

      const {
        public: { baseUrl },
      } = useRuntimeConfig();
      const fullUrl = `${baseUrl}${route.fullPath}`;
      const imageUrl = `${baseUrl}/images/Logo/TD_dotamix2.jpg`;

      const { title, description } = seoData;

      useHead({
        link: [{ href: fullUrl, rel: 'canonical' }],
        meta: [
          {
            content: title,
            property: 'vk:title',
          },
          {
            content: description,
            name: 'description',
          },
          {
            content: description,
            property: 'vk:description',
          },
          {
            content: description,
            property: 'vk:text',
          },
          {
            content: fullUrl,
            property: 'vk:url',
          },
          {
            content: imageUrl,
            property: 'vk:image',
          },
          {
            content: 'website',
            property: 'og:type',
          },
          {
            content: title,
            property: 'og:title',
          },
          {
            content: description,
            property: 'og:description',
          },
          {
            content: title,
            property: 'og:site_name',
          },
          {
            content: fullUrl,
            property: 'og:url',
          },
          {
            content: imageUrl,
            property: 'og:image',
          },
        ],
        title,
      });
    },
    { immediate: true },
  );
};
