import { EHeaderMenuItems, EStatisticItemVariants, type IAppsConfig } from '~/config/apps/index.types';

export const useProjectSettings = () =>
  <IAppsConfig>{
    project: 'cs-global',
    game: 'cs',
    theme: 'gray',
    defaultCurrency: 'USD',
    siteCurrency: 'GOLD',
    auth: {
      isCheckboxesSelectedByDefault: false,
      variants: ['steam', 'google'],
    },
    cases: {
      showButtons: false,
      priceProject: 'CSLAND',
      siteName: 'CS Land',
    },
    daily: {
      hideFromTop: true,
      hideTimer: true,
    },
    contacts: {
      adMail: 'media_ts@tastyteam.cc',
      supportMail: 'support@tastyteam.cc',
      partnerMail: 'partner@tastyteam.cc',
      hideVk: true,
    },
    faq: {
      type3: {
        categoryId: 1,
        questionId: 1,
      },
    },
    footer: {
      copyright: 'CS Land',
      paymentMethods: ['visa', 'mastercard', 'bitcoin', 'tether', 'ton'],
      hideSocials: true,
    },
    legalData: {
      beneficiary: 'Big Corgi Development OÜ',
      registrationNumber: 16252850,
      address: 'Harju maakond, Tallinn, Kesklinna linnaosa, Masina tn 22, 10113',
    },
    isBattlesDisabled: true,
    isChallengesDisabled: true,
    isProvablyFairDisabled: false,
    isUpgradesDisabled: true,
    isTechiesDisabled: false,
    hideBattlesBanner: true,
    hideSecretShop: true,
    hideFreeCasesBanner: false,
    showPacksBanner: false,
    hideHeaderMenuItems: [
      EHeaderMenuItems.BONUS_PAGE,
      EHeaderMenuItems.SECRETSHOP,
      EHeaderMenuItems.UPGRADES,
      EHeaderMenuItems.CONTRACT,
      EHeaderMenuItems.TECHIES,
    ],
    header: {
      bottomMenu: {
        left: ['home', 'upgrades'],
        right: ['daily', 'menu'],
      },
      sideMenu: ['daily', 'bonuses', 'upgrades', 'faq', 'pf', 'telegram', 'discord'],
      desktopMenu: ['upgrades'],
      customLinks: {
        telegram: 'https://t.me/tastydrop',
        discord: 'https://discord.com/invite/2v6x9m2',
      },
      otherGamesLinks: {
        dota: 'https://click.open-case-welcome.com/km57ewlj?sub_id1=crosssaleTD&sub_id2=header&sub_id3=cl&sub_id4=cl&sub_id5=FRIENDDB',
      },
    },
    hideProjectSwitcher: true,
    howItWorks: {
      hideStep6: true,
    },
    supportKey: 'sePmTTSmEQ',
    gtmKey: 'GTM-WPQRW3B',
    seo: {
      favicon: '/img/favicon/cs.ico',
      title: 'CS Land - CS 2 cases',
      description:
        'CS Land — largest CS 2 cases site. Buy CS 2 cases and get high quality skins on your favorite weapon! Withdraw your winnings to Steam within 1 minute.',
    },
    statistics: {
      hideElements: [EStatisticItemVariants.ARCANES, EStatisticItemVariants.BATTLES],
    },
    techWork: {
      logoClass: 'logo--dota',
    },
    payment: {
      project: 'CS Land',
    },
    redirects: {
      newCases: '/#all-cases',
    },
    profile: {
      hideSteamLogin: true,
    },
    subscriptions: {
      variants: ['email', 'steam'],
      show: false,
    },
    drop: {
      hideShare: true,
    },
    upgrades: {
      priceMaxDifference: 9000,
      progressVars: {
        '--center': '#171A21',
        '--stroke': '#4E5165',
        'rgb': '0 0 0 0 0.564706 0 0 0 0 0.596078 0 0 0 0 0.717647 0 0 0 1 0',
      },
    },
  };
