export const useGTM = () => {
  if (!import.meta.client) return;

  const { gtmKey } = useProjectSettings();

  useScript(
    {
      key: 'gtm-init',
      src: `https://www.googletagmanager.com/gtag/js?id=${gtmKey}`,
      defer: true,
      tagPriority: 'low',
    },
    { trigger: 'onNuxtReady' },
  );
  useScript(
    {
      key: 'gtm-config',
      src: '',
      defer: true,
      tagPriority: 'low',
      innerHTML: `
          const noscriptElement = document.createElement('noscript');
          const iframeElement = document.createElement('iframe');

          iframeElement.src = "https://www.googletagmanager.com/ns.html?id=${gtmKey}";
          iframeElement.height = "0";
          iframeElement.width = "0";
          iframeElement.style.display = "none";
          iframeElement.style.visibility = "hidden";

          noscriptElement.appendChild(iframeElement);
          document.body.appendChild(noscriptElement);
        
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${gtmKey}');
        `,
    },
    { trigger: 'onNuxtReady' },
  );
};
