import { useUserStore } from '~/store/user/user.store';

export const useZendesk = () => {
  const userStore = useUserStore();
  const { user } = storeToRefs(userStore);

  const { supportKey } = useProjectSettings();

  if (!user.value) return;

  useScript(
    {
      key: 'zendesk',
      src: `//code.jivosite.com/widget/${supportKey}`,
      defer: true,
    },
    {
      trigger: 'onNuxtReady',
    },
  );
  useScript(
    {
      innerHTML: `
            function jivo_onLoadCallback() {
              const userId = ${user.value.userId};
              jivo_api.setContactInfo({
                name: '${user.value.nickname} (${user.value.userId})'
              });
            }
          `,
      key: 'zendesk-callback',
      src: '',
      defer: true,
    },
    {
      trigger: 'onNuxtReady',
    },
  );
};
