import { Colors } from '../constants/colors.constants';

const LEVELS = [
  [1, 4],
  [5, 9],
  [10, 14],
  [15, 19],
  [20, 29],
  [30, 39],
  [40, 49],
  [50, 59],
  [60, 69],
  [70, 79],
  [80, 89],
  [90, 99],
  [100, 109],
  [110, 119],
  [120, 129],
  [130, 139],
  [140, 149],
  [150, 159],
  [160, 169],
  [170, 179],
  [180, 189],
  [190, 199],
  [200],
];

export const getColorVariableByLevel = (level: number) => {
  if (level < 1) return 'var(--lvl-1-4)';
  const validLevel = LEVELS.find(([min, max]) => level >= min && level <= max) ?? LEVELS[LEVELS.length - 1];

  return `var(--lvl-${validLevel.join('-')})`;
};

/**
 * Возвращает объект с rgba значениями или строку
 * из переданной hex строки
 * @param {string} hex - HEX строка
 * @param {Object | string} returnType - Тип возвращаемого значения функции
 * @param {number} alpha - Добавочное значение альфа коэффициента от 0 до 1
 * @returns {Object | string} - Возвращает или объект или строку с rgba значением
 */
function hexToRgb(
  hex: string,
  returnType: 'object',
  alpha?: number,
): {
  alpha: number;
  b: number;
  g: number;
  r: number;
};
function hexToRgb(hex: string, returnType: 'string', alpha?: number): string;
function hexToRgb(hex: string, returnType: 'object' | 'string', alpha = 1): unknown {
  if (alpha && alpha < 0 && alpha > 1) throw new Error(`Invalid alpha value ${alpha}`);

  const [R, G, B] = [parseInt(hex.slice(1, 3), 16), parseInt(hex.slice(3, 5), 16), parseInt(hex.slice(5, 7), 16)];

  if (returnType === 'string') return `rgb${alpha ? 'a' : ''}(${R},${G},${B}${alpha ? ',' + alpha : ''})`;
  return {
    alpha,
    b: B,
    g: G,
    r: R,
  };
}
const hex2Rgb = hexToRgb;

/**
 * Возвращает набором цветов в зависимости от переданного уровня пользователя
 * @param lvl - Значение уровня пользователя
 * @returns - Набор цветов в зависимости от переданного уровня
 */
const parseLvlColors = (lvl: 'free' | number | null) => {
  const level = toValue(lvl);

  if (level === 'free') {
    return Colors.LVL.FREE_CASES;
  } else if (!level) {
    return Colors.LVL.LVL_1_4;
  } else if (level >= 200) {
    return Colors.LVL.LVL_200;
  }

  const splitLvl = (lvlKey: string) => lvlKey.split('_');

  const currentLvlKey = ref(
    Object.keys(Colors.LVL).find((key) => {
      const [, min, max] = splitLvl(key);
      return min && max && level >= +min && level <= +max;
    }),
  );

  if (!currentLvlKey.value) return Colors.LVL.LVL_1_4;

  return Colors.LVL[currentLvlKey.value as keyof typeof Colors.LVL];
};

const getColorsRarity = (color: string): string => {
  const rarityKey = color.toLowerCase().replace('_', '-');

  return `var(--rarity-${rarityKey})`;
};

const getGradientColorsRarity = (rarity: string): string => {
  if (typeof rarity !== 'string') return '';
  const rarityModified = rarity.toLowerCase().replace('_', '-');
  return `var(--rarity-gradient-${rarityModified})`;
};

/**
 * Возвращает валидный hex цвет с "#" символом в начале
 * @param {string} color - Hex цвет с/без символом "#"
 * @returns {string} - Hex цвет с символом "#"
 */
const passColorThroughHash = (color: string) => {
  const HASH_SYMBOL = '#';
  if (color[0] !== HASH_SYMBOL) return HASH_SYMBOL + color;
  return color;
};

export default {
  Colors: {
    getColorsRarity,
    getGradientColorsRarity,
    hex2Rgb,
    parseLvlColors,
    passColorThroughHash,
  },
};
