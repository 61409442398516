import type { TFooterPaymentMethods } from '~/controllers/FooterController/PaymentMethods/PaymentMethods.types';
import type { TOAuthKey } from '~/features/general-auth/components/ButtonsGroup/ButtonsGroup.types';
import type { THeaderGamesKeys } from '~/features/header/constants/games.constants';
import type { THeaderMenuKeys } from '~/features/header/constants/menuItems.constants';

interface IAppsConfigAuth {
  isCheckboxesSelectedByDefault: boolean;
  variants: TOAuthKey[];
}

export enum EHeaderMenuItems {
  PF = 'pf',
  BATTLES = 'battles',
  BONUS_PAGE = 'bonusPage',
  CONTRACT = 'contract',
  FREE_CASES = 'freeCases',
  LEADERBOARD = 'leaderboard',
  SECRETSHOP = 'secretShop',
  TECHIES = 'techies',
  UPGRADES = 'upgrades',
}

export enum EStatisticItemVariants {
  ARCANES = 'arcanes',
  BATTLES = 'battles',
  ONLINE = 'online',
  OPENED = 'opened',
  UPGRADES = 'upgrades',
  USERS = 'users',
}

export interface IAppsConfigSeo {
  favicon: string;
  title: string;
  description: string;
}

export interface IAppsConfigTechWork {
  logoClass: string;
}

export interface IAppsConfigFaq {
  type3: {
    categoryId: number;
    questionId: number;
  };
}

export interface IAppsConfigDrop {
  hideShare: boolean;
}

export interface IAppsConfigHowItWorks {
  hideStep6: boolean;
}

export interface IAppsConfigFooter {
  copyright: string;
  paymentMethods: TFooterPaymentMethods[];
  hideSocials: boolean;
}

export interface IAppsConfigStatistics {
  hideElements: EStatisticItemVariants[];
}

export interface IAppsConfigRedirects {
  newCases: string;
}

export interface IAppsConfigLegalData {
  beneficiary: string;
  registrationNumber: number;
  address: string;
}

export interface IAppsConfigContacts {
  adMail: string;
  supportMail: string;
  partnerMail: string;
  hideVk: boolean;
}

export interface IAppConfigCases {
  showButtons: boolean;
  priceProject: string;
  siteName: string;
}

export interface IAppSubscriptionsRules {
  variants: string[];
  show: boolean;
}

export interface IAppsConfigPayment {
  project: string;
}

export interface IAppConfigUpgradesProgressVars {
  '--center': string;
  '--stroke': string;
  'rgb': string;
}

export interface IAppConfigUpgrades {
  progressVars: IAppConfigUpgradesProgressVars;
  priceMaxDifference: number;
}

export interface IAppsConfigProfile {
  hideSteamLogin: boolean;
}

export interface IAppsConfigHeader {
  bottomMenu: {
    left: THeaderMenuKeys[];
    right: THeaderMenuKeys[];
  };
  sideMenu: THeaderMenuKeys[];
  desktopMenu: THeaderMenuKeys[];
  customLinks: {
    [key in THeaderMenuKeys]?: string;
  };
  otherGamesLinks: {
    [key in THeaderGamesKeys]?: string;
  };
}

export interface IAppsConfigDaily {
  hideTimer: boolean;
  hideFromTop: boolean;
}

export interface IAppsConfig {
  project: 'dota' | 'cs' | 'dota-global' | 'cs-global';
  theme: 'gray' | 'blue' | 'purple';
  game: 'cs' | 'dota';
  defaultCurrency: 'RUB' | 'USD';
  siteCurrency: 'RUB' | 'USD' | 'GOLD';
  auth: IAppsConfigAuth;
  cases: IAppConfigCases;
  contacts: IAppsConfigContacts;
  header: IAppsConfigHeader;
  daily: IAppsConfigDaily;
  drop: IAppsConfigDrop;
  faq: IAppsConfigFaq;
  footer: IAppsConfigFooter;
  howItWorks: IAppsConfigHowItWorks;
  legalData: IAppsConfigLegalData;
  isBattlesDisabled: boolean;
  isChallengesDisabled: boolean;
  isUpgradesDisabled: boolean;
  isProvablyFairDisabled: boolean;
  isTechiesDisabled: boolean;
  hideBattlesBanner: boolean;
  hideFreeCasesBanner: boolean;
  showPacksBanner?: boolean;
  hideSecretShop: boolean;
  hideHeaderMenuItems: EHeaderMenuItems[];
  hideProjectSwitcher: boolean;
  supportKey: string;
  gtmKey: string;
  seo: IAppsConfigSeo;
  statistics: IAppsConfigStatistics;
  payment: IAppsConfigPayment;
  profile: IAppsConfigProfile;
  techWork: IAppsConfigTechWork;
  redirects: IAppsConfigRedirects;
  subscriptions: IAppSubscriptionsRules;
  upgrades: IAppConfigUpgrades;
}
