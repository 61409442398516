import { useScript, defineNuxtPlugin } from '#imports'

export default defineNuxtPlugin({
  name: "scripts:init",
  env: { islands: false },
  parallel: true,
  setup() {
    const push = useScript({"key":"push","trigger":"onNuxtReady","async":true,"src":"//web.webpushs.com/js/push/c6e29a748a11513e36ed2cce4174d3c2_1.js"}, { use: () => ({ push: window.push }) })
    const yshare = useScript({"key":"yshare","async":true,"src":"https://yastatic.net/share2/share.js","trigger":"onNuxtReady"}, { use: () => ({ yshare: window.yshare }) })
    return { provide: { $scripts: { push, yshare } } }
  }
})